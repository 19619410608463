import { graphql } from 'gatsby'
import PostPage from '../containers/Post'

export default PostPage

export const pageQuery = graphql`
  query PostPageQuery(
    $id: String!
    $previousId: String
    $nextId: String
    $categoryId: String
    $tagsIds: [String]
    $title: String!
    $hasTags: Boolean!
    $includeExcerpt: Boolean!
    $includeTimeToRead: Boolean!
    $includeTableOfContents: Boolean!
    $imageQuality: Int!
  ) {
    post: article(id: { eq: $id }) {
      ...ArticleInformation
      ...ArticleThumbnailRegular
    }
    #slides
    slides: allContentfulSlides(
    filter: {article: {elemMatch: {title: {eq: $title}}}}
      ) {
        edges {
          node {
            id
            slideTitle
            slideImage {
              gatsbyImageData
            }
            slideBody {
              slideBody
            }
          }
        }
      }

    #file based slides
    fileSlides: allMdxArticleProxy(filter: {title: {eq: $title}}) {
    nodes {
      fileSlides {
        slideBody
        slideTitle
        slideImage
        slideButtonText
        slideButtonLink
      }
    }
  }
    
    #slides: allArticleSlide(filter: {parent: {id: {eq: $id}}}) {
     # nodes {
      #  slideTitle
       # slideBody
     # }
    #}

    # Related posts based on tags and category
    tagCategoryPosts: allArticle(
      filter: {
        private: { ne: true }
        draft: { ne: true }
        tags: { elemMatch: { id: { in: $tagsIds } } }
        category: { id: { eq: $categoryId } }
        id: { ne: $id }
      }
      sort: { fields: [date], order: DESC }
      limit: 6
    ) @include(if: $hasTags) {
      nodes {
        ...ArticlePreview
        ...ArticleThumbnailRegular
      }
    }

    # Related posts based on tags only
    tagPosts: allArticle(
      filter: {
        private: { ne: true }
        draft: { ne: true }
        tags: { elemMatch: { id: { in: $tagsIds } } }
        id: { ne: $id }
      }
      sort: { fields: [date], order: DESC }
      limit: 6
    ) @include(if: $hasTags) {
      nodes {
        ...ArticlePreview
        ...ArticleThumbnailRegular
      }
    }

    # Related posts based on category only
    categoryPosts: allArticle(
      filter: {
        private: { ne: true }
        draft: { ne: true }
        category: { id: { eq: $categoryId } }
        id: { ne: $id }
      }
      sort: { fields: [date], order: DESC }
      limit: 6
    ) {
      nodes {
        ...ArticlePreview
        ...ArticleThumbnailRegular
      }
    }

    previous: article(id: { eq: $previousId }) {
      id
      slug
      title
    }

    next: article(id: { eq: $nextId }) {
      id
      slug
      title
    }
  }
`
