import React from "react";

const GoogleAds = ({ slot, layout, placeholder, adkey}) => {
    React.useEffect(() => {
        if (process.env.NODE_ENV !== "development") {
            if (window) {
                try {
                    (window.adsbygoogle = window.adsbygoogle || []).push({});
                } catch (error) {
                    console.log(error, "Ads by google Error");
                }

                /*const cmGoogButtonHuggers = document.querySelectorAll('.cmGoogButtonHugger');
                if (cmGoogButtonHuggers) {
                    cmGoogButtonHuggers.forEach((cmGoogButtonHugger, index) => {
                        const aSwift = cmGoogButtonHugger.querySelector('ins div');
                        if (aSwift) {
                            const height = aSwift.offsetHeight;
                            if (height > 0 ) {
                                cmGoogButtonHugger.style.height = height + 'px';
                            }
                        }
                        setTimeout(() => {
                            const aSwift = cmGoogButtonHugger.querySelector('ins div');
                            if (aSwift) {
                                const height = aSwift.offsetHeight;
                                cmGoogButtonHugger.style.height = height + 'px';
                            } else {
                                cmGoogButtonHugger.style.height = '0px';
                            }
                        }, 1000);
                    });
                }*/


            }
        }
    }, []);

    // In development environment, return a placeholder image
    if (process.env.NODE_ENV === "development") {
        return (

            <img
                src={`https://placehold.co/${placeholder}`}
                alt="Google Ad"
                style={{ display: "block", width: "100%" }}
            />
        );
    }

    return (
        <ins
            className="adsbygoogle"
            style={{ display: "block" }}
            data-ad-format="fluid"
            data-ad-layout-key={layout}
            data-ad-client="ca-pub-5555030135342556"
            data-ad-slot={slot}
            key={adkey}
        ></ins>
    );
};

export default GoogleAds;

GoogleAds.defaultProps = {
    slot: "5896950002",
    layout: "in-article",
    placeholder: "300x250",
    adkey: Math.random()
};
