import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby';
import { Card } from 'theme-ui'
import { Layout, Stack, Main, Sidebar } from '@layout'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import GoogleAds from '@components/GoogleAds'
import Sticky from '@components/Sticky'
import Seo from '@widgets/Seo'
import AuthorCompact from '@widgets/AuthorCompact'
import TableOfContentsCompact from '@widgets/TableOfContentsCompact'
import {
    PostHead,
    PostImage,
    PostBody,
    PostComments,
    PostCommentsFacebook,
    PostCommentsGraph,
    PostTagsShare,
    PostFooter
} from '@widgets/Post'

const Post = ({
      data: { post, slides, fileSlides, tagCategoryPosts, tagPosts, categoryPosts, previous, next },
      ...props
    }) => {

    const relatedPosts = [
        ...(tagCategoryPosts ? tagCategoryPosts.nodes : []),
        ...(tagPosts ? tagPosts.nodes : []),
        ...(categoryPosts ? categoryPosts.nodes : [])
    ]

    const { pageContext: { services = {}, siteUrl } = {} } = props

    return (
        <Layout {...props}>
         
            <Seo {...post} siteUrl={siteUrl} />
         
            <Stack effectProps={{ effect: 'fadeInDown' }}>
                <PostHead {...post} />
            </Stack>
          
            <Stack effectProps={{ fraction: 0 }}>
                <Main>
                    <Card variant='paper'>
                        <PostImage {...post} inCard />
                        <PostBody {...post} destination={post.destinationUrl} slideData={slides} fileSlideData={fileSlides} />

                        <PostTagsShare {...post} location={props.location} />
                        {services.disqus && <PostComments {...post} />}
                        {services.graphComment && <PostCommentsGraph {...post} />}
                        {services.facebookComment && (
                            <PostCommentsFacebook {...post} siteUrl={siteUrl} />
                        )}
                        <PostFooter {...{ previous, next }} />
                    </Card>
                </Main>
                <Sidebar>
                    {(siteUrl === 'https://myne.ws') ? (
                        <div className='cmGoogAdsSidebar'><GoogleAds slot={5896950002} layout={"in-article"} placeholder={"300x250"} adkey={Math.random()} /></div>
                    ) : ""}
                    {(siteUrl === 'https://bestlife.lat') ? (
                        <div className='cmRcWrap'>
                            <div id="rc-widget-e706ca-blrcsidebar" data-rc-widget data-widget-host="habitat" data-endpoint="//trends.revcontent.com" data-widget-id="277129"></div>
                        </div>
                    ) : ""}

                    <AuthorCompact author={post.author} omitTitle />
                    <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                        {post.tableOfContents?.items && (
                            <>
                                <TableOfContentsCompact {...post} />

                            </>
                        )}
                        {post.category && (
                            <CardList
                                omitTitle
                                nodes={relatedPosts}
                                variant='horizontal-aside'
                                limit={3}
                                omitMedia
                                omitCategory
                                distinct
                                aside
                            />
                        )}
                    </div>
                    <Sticky>
                        {(siteUrl === 'https://myne.ws') ? (
                            <div className='cmGoogAdsSidebar'><GoogleAds slot={5896950002} layout={"in-article"} placeholder={"300x250"} adkey={Math.random()} /></div>
                        ) : ""}
                    </Sticky>
                </Sidebar>
            </Stack>
            
        </Layout>
    )
}

export default Post
