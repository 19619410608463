import React, { useState, useEffect } from 'react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@theme-ui/mdx'
import {Button, css} from 'theme-ui'
import { Link } from 'gatsby'
import components from '@components/Mdx'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import useSiteMetadata from '@helpers-blog/useSiteMetadata'
import GoogleAds from "@components/GoogleAds";

const getQueryStringValue = (key) => {
    if (typeof window === 'undefined') {
        return null;
    }
    return new URLSearchParams(window.location.search).get(key);
};

export const PostBody = ({ body, destination, slideData, fileSlideData }) => {

    let slides = [];

    if (slideData?.edges?.length) {
        //console.log('Mapping slideData');
        slides = slideData.edges.map(({ node }) => ({
            id: node.id,
            title: node.slideTitle,
            image: node.slideImage?.gatsbyImageData,
            body: node.slideBody?.slideBody,
        }));
    } else if (fileSlideData?.nodes?.[0]?.fileSlides?.length) {
        //console.log('Mapping fileSlideData');
        slides = fileSlideData.nodes[0].fileSlides.map((node, index) => ({
            id: `fileslide-${index}`,
            title: node.slideTitle,
            body: node.slideBody,
            image: node.slideImage,
            buttonText: node.slideButtonText,
            buttonLink: node.slideButtonLink
        }));
    }

    const site = useSiteMetadata()

    const pageviewAction = (slide) => {
        if (typeof window !== 'undefined') {

            //scroll user up
            window.scroll(0, 0);

            //fire GA events
            if (typeof window.gtag !== 'undefined') {
                window.gtag("event", "click", {})
            }

            //remove hero image so slide takes up body
            const primaryArt = document.getElementById('primaryArt');
            const primaryArtAd = document.querySelectorAll('.cmGoogBelowImagePrimaryArt');
            primaryArt && primaryArt.closest('.gatsby-image-wrapper')?.setAttribute('style', 'height: 0px; min-height: 0px; display: none;');
            primaryArtAd.length && primaryArtAd.forEach((el) => el && el.setAttribute('style', 'height: 0px; min-height: 0px; display: none;'));

            //make sure RC renders
            window.requestAnimationFrame(() => {
                let rc_wid;
                if (site.title === "MyNews") {
                    rc_wid = 276647;
                } else if (site.title === "BestLife") {
                    rc_wid = 277128;
                }

                let rcNextWidget = document.getElementById('rc-widget-e706ca-slide-next');
                if (rcNextWidget) {
                    rcNextWidget.setAttribute('data-rc-widget', '');
                    rcNextWidget.setAttribute('data-endpoint', 'trends.revcontent.com');
                    rcNextWidget.setAttribute('data-widget-id', rc_wid);
                    if (typeof window.renderRCWidget === 'function') {
                        window.renderRCWidget(rcNextWidget);
                    }
                }
            });
        }
    };

    const [showSlides, setShowSlides] = useState(false)
    const [currentSlide, setCurrentSlide] = useState(0)
    const [slideshowEnabled, setSlideshowEnabled] = useState(false)
    const [hideRc, setHideRc] = useState(true)
    const [editorialMode, seteditorialMode] = useState(false)

    useEffect(() => {
        if (getQueryStringValue('utm_source') === 'agentOrange') {
            setSlideshowEnabled(true)
        }
    }, [])

    useEffect(() => {
        if (getQueryStringValue('e') === '1') {
            seteditorialMode(true)
        }
    }, [])

    useEffect(() => {
        if (getQueryStringValue('rc') === '0') {
            setHideRc(false)
        }
    }, [])

    const handleStartSlideshow = () => {
        setShowSlides(true)
        pageviewAction(0);
    }

    const handlePreviousSlide = () => {
        if (currentSlide > 0) {
            setCurrentSlide(currentSlide - 1)
            pageviewAction(currentSlide - 1);
        } else {
            window.location.reload()
        }
    }

    const handleNextSlide = () => {
        if (currentSlide < slides.length - 1) {
            setCurrentSlide(currentSlide + 1)
            pageviewAction(currentSlide + 1);
        } else {
            window.location.href = '/end'
        }
    }

    const bodyContent = (
        <MDXProvider components={components}>
            <div id={editorialMode === true ? 'inEditMode' : 'notEditMode'}>
            {!showSlides && <MDXRenderer>{body}</MDXRenderer>}
            {!slideshowEnabled && !showSlides && site.title === "MyNews" && hideRc && (
                <div className='cmRcWrap'>
                    <div id="rc-widget-e706ca" data-rc-widget data-widget-host="habitat" data-endpoint="//trends.revcontent.com" data-widget-id="277127"></div>
                </div>
            ) }
            {!slideshowEnabled && !showSlides && site.title === "BestLife" && hideRc && (
                <div className='cmRcWrap'>
                    <div id="rc-widget-e706ca" data-rc-widget data-widget-host="habitat" data-endpoint="//trends.revcontent.com" data-widget-id="277128"></div>
                </div>
            ) }


            {slideshowEnabled && !showSlides && (
                <>
                    {site.title === "MyNews" && (
                        <div className='cmGoogButtonHugger'>
                            <GoogleAds slot={2469922452} layout={"-70+ex+e-5b+7u"} placeholder={"468x100"} adkey={Math.random()} />
                        </div>
                    )}

                    <Button onClick={handleStartSlideshow} className='start-slideshow'>
                        Start Slideshow <span className='cmCrazyArrows'>➜</span>
                    </Button>

                    {site.title === "MyNews" && (
                        <div>
                            <div className='cmGoogButtonHugger'>
                                <GoogleAds slot={2469922452} layout={"-70+ex+e-5b+7u"} placeholder={"468x100"} adkey={Math.random()}/>
                            </div>
                            { hideRc && (
                            <div className='cmRcWrap'>
                                <div id="rc-widget-e706ca" data-rc-widget data-widget-host="habitat" data-endpoint="//trends.revcontent.com" data-widget-id="276647"></div>
                            </div>
                            )}
                        </div>
                    )}

                    {site.title === "BestLife" && (
                        <div>
                            <div className='cmGoogButtonHugger'>
                                <GoogleAds slot={2298387244} layout={"-70+ex+e-5b+7u"} placeholder={"468x100"} adkey={Math.random()}/>
                            </div>
                            { hideRc && (
                            <div className='cmRcWrap'>
                                <div id="rc-widget-e706ca" data-rc-widget data-widget-host="habitat" data-endpoint="//trends.revcontent.com" data-widget-id="277128"></div>
                            </div>
                            )}
                        </div>
                    )}
                </>
            )}
            {slideshowEnabled && showSlides && slides?.length > 0 && (
                <div>
                    <div className='slideShowEnabled' key={slides[currentSlide].id}>
                        <h2 className='cmSlideTitle'>{slides[currentSlide].title}</h2>

                        {slides[currentSlide].image && (
                            typeof slides[currentSlide].image === "object" ? (
                                <GatsbyImage image={slides[currentSlide].image} alt={slides[currentSlide].title} />
                            ) : (
                                <img src={slides[currentSlide].image} alt={slides[currentSlide].title} className='cmSlideshowImage'/>
                            )
                        )}

                        {site.title === "MyNews" && (
                            <div className='cmGoogBelowImage'>
                                <GoogleAds slot={3517951256} layout={"-fb+5w+4e-db+86"} placeholder={"468x80"} adkey={Math.random()}/>
                            </div>
                        )}

                        {site.title === "BestLife" && (
                            <div className='cmGoogBelowImage'>
                                <GoogleAds slot={6775854158} layout={"-fb+5w+4e-db+86"} placeholder={"468x80"} adkey={Math.random()}/>
                            </div>
                        )}

                        <p className='cmSlideText'>{slides[currentSlide].body}</p>

                    </div>


                    {site.title === "MyNews" && (
                        <div className='cmGoogButtonHugger'>
                            <GoogleAds slot={2469922452} layout={"-70+ex+e-5b+7u"} format={"responsive"} placeholder={"468x100"} adkey={Math.random()}/>
                        </div>
                    )}

                    <Button onClick={handleNextSlide} disabled={currentSlide === slides.length - 1 && slideshowEnabled === false} className='next-button-right cmNexSlideBigBoy'>
                        Next Slide <span className='cmCrazyArrows'>➜</span>
                    </Button>

                    {site.title === "MyNews" && (
                        <div>
                            <div className='cmGoogButtonHugger'>
                                <GoogleAds slot={9587416028} layout={"-70+ex+e-5b+7u"} placeholder={"468x100"} adkey={Math.random()}/>
                            </div>
                            { hideRc && (
                            <div className='cmRcWrap'>
                                <div id="rc-widget-e706ca-slide-next" data-widget-host="habitat" ></div>
                            </div>
                            )}
                        </div>
                    )}

                    {site.title === "BestLife" && (
                        <div>
                            <div className='cmGoogButtonHugger'>
                                <GoogleAds slot={2298387244} layout={"-70+ex+e-5b+7u"} placeholder={"468x100"} adkey={Math.random()}/>
                            </div>
                            { hideRc && (
                            <div className='cmRcWrap'>
                                <div id="rc-widget-e706ca-slide-next" data-widget-host="habitat" ></div>
                            </div>
                            )}
                        </div>
                    )}
                </div>
            )}

            {!slideshowEnabled && (
                <>
                    {slides?.map((slide) => (
                        <div key={slide.id}>
                            <h2 className='cmSlideTitle'>{slide.title}</h2>
                            
                            {slide.image && (
                                typeof slide.image === "object" ? (
                                    <GatsbyImage image={slide.image} alt={slide.title} />
                                ) : (
                                    slide.image.includes("pixel.png") ? (
                                    <img src={slide.image} alt={slide.title} className='cmSlideshowPixel' />
                                    ) : (
                                    (slide.buttonLink && slide.buttonText?.trim()) ? (
                                        <a href={slide.buttonLink} title={slide.buttonText} target='_blank'>
                                            <img src={slide.image} alt={slide.title} className='cmSlideshowImage' />
                                        </a>
                                    ) : (
                                        <img src={slide.image} alt={slide.title} className='cmSlideshowImage' />
                                    )
                                    )
                                )
                            )}

                            {site.title === "MyNews" && (
                                <div className='cmGoogBelowImage'>
                                    <GoogleAds slot={3517951256} layout={"-fb+5w+4e-db+86"} placeholder={"468x80"} adkey={Math.random()}/>
                                </div>
                            )}
                          
                            {slide.body.split('\n').map((line, index) => (
                                <React.Fragment key={index}>
                                    {line.includes('http') ? (
                                    <p
                                        dangerouslySetInnerHTML={{
                                        __html: line.replace(
                                            /<a href='(.*?)'>(.*?)<\/a>/,
                                            '<a href="$1" target="_blank">$2</a>'
                                        ),
                                        }}
                                    />
                                    ) : (
                                    <p>{line}</p>
                                    )}
                                </React.Fragment>
                            ))}

                            {slide.buttonLink && slide.buttonText && (
                            <a href={slide.buttonLink} target='_blank' rel='noopener noreferrer'>
                                <button className='start-slideshow'>{slide.buttonText}</button>
                            </a>
                            )}
                        </div>
                    ))}
                </>
            )}
            {destination && destination !== "" && (
                <a className='cmLinks' href={destination} target='_blank'>
                    <Button variant='primary'>
                        <b>Read More &nbsp;&nbsp;>></b>
                    </Button>
                </a>
            )}

        </div>        
        </MDXProvider>
    )

    return bodyContent
}